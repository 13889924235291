@import 'styles/variables';

body {
    font-family: 'Exo', sans-serif;
    margin: 0;
}

// @media (prefers-color-scheme: light) {
//     body {
//         //background-color: #ffffff;
//         background-color: #e5e3e3;
//         color: $text;
//         .container {
//             .primary {
//                 .profile {
//                     .greeting {
//                         h3 {
//                             color: $text;
//                         }
//                     }
//                 }
//             }
//         }
//     }

// }

// @media (prefers-color-scheme: dark) {
//     body {
//         background-color: #131313;
//         color: #c4c4c4;
//         .container {
//             .primary {
//                 .profile {
//                     .greeting {
//                         h3 {
//                             color: #ffffff;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

html {
    scroll-behavior: smooth;
}

body {
    //background-color: #ffffff;
    background-color: #e5e3e3;
    color: $text;

    .container {
        //background-color: #fff;
        // background-image:
        //   radial-gradient(at top left, #db89d1, transparent),
        //   radial-gradient(at top right, #e0753c, transparent),
        //   radial-gradient(at bottom left, #5ba4d9, transparent);
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        // background: #D38312;  /* fallback for old browsers */
        // background: -webkit-linear-gradient(to right, #A83279, #D38312);  /* Chrome 10-25, Safari 5.1-6 */
        // background: linear-gradient(to right, #A83279, #D38312); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

        //     background-color: #4158D0;
        // background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);

        .primary {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10%;
            margin-right: 10%;
            //margin-bottom: 10%;
            width: 80%;
            height: 100vh;
            .profile {
                width: 100%;
                //justify-content: center;
                //margin-left: 10px;
                //align-items: center;
                justify-content: center;
                display: flex;
                background-image: url('./assets/dev2.svg');
                background-position: 90% 80%;
                //background-size: 750px;
                background-repeat: no-repeat;
                height: 550px;
                img {
                    width: 500px;
                }
                .greeting {
                    margin-top: 5%;
                    //margin-left: 10%;
                    padding-left: 10%;
                    h2 {
                        font-weight: 700;
                        background-image: linear-gradient(to bottom right, #A83279, #D38312);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent; 
                        -moz-background-clip: text;
                        -moz-text-fill-color: transparent;
                        font-size: 40px;
                        margin: 0;
                    }
                    h3 {
                        font-size: 30px;
                        font-weight: 600;
                        color: $text;
                        margin: 5px;
                    }
                    h4 {
                        display: none;
                    }
                }
            }
        }
        .about {
        //margin: 10%;
            //width: 90%;
            //background-image: linear-gradient(to bottom right,  #A83279, #D38312);
            //height: 100vh;
            // border-top-right-radius: 150px;
            // border-top-left-radius: 150px;
            // border-bottom-right-radius: 150px;
            // border-bottom-left-radius: 150px;
            // -webkit-box-shadow: 12px -7px 83px -7px rgba(0,0,0,0.51);
            // -moz-box-shadow: 12px -7px 83px -7px rgba(0,0,0,0.51);
            // box-shadow: 12px -7px 83px -7px rgba(0,0,0,0.51);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 50px;
            margin-left: 5%;
            margin-right: 5%;
            margin-bottom: 15%;
            h1 {
                //background-image: linear-gradient(to bottom right, #A83279, #D38312);
                background-color: $text;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; 
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
                font-size: 45px;
                font-weight: 700;
                padding: 50px;
            }
            .aboutText{
                display: flex;
                padding-left: 50px;
                padding-right: 50px;
                //flex-direction: row;
                justify-content: space-around;
                .aboutLeft {
                    flex-grow: 1;
                    max-width: 300px;
                    align-items: center;
                    padding: 20px;
                    background-color: #ffffff;
                    -webkit-box-shadow: 10px -7px 50px -7px rgba(0,0,0,0.1);
                    -moz-box-shadow: 10px -7px 50px -7px rgba(0,0,0,0.1);
                    box-shadow: 10px -7px 50px -7px rgba(0,0,0,0.1);
                    border-radius: 10px;
                    margin: 20px;
                    p {
                        text-align: center;
                        color: $text;
                        font-size: 20px;
                    }
                    .flags {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 50px;
                            padding: 10px;
                        }
                    }
                    
                }
                .aboutRight {
                   flex-grow: 1;
                   max-width: 300px;
                    align-items: center;
                    padding: 20px;
                    background-color: #ffffff;
                    -webkit-box-shadow: 10px -7px 50px -7px rgba(0,0,0,0.1);
                    -moz-box-shadow: 10px -7px 50px -7px rgba(0,0,0,0.1);
                    box-shadow: 10px -7px 50px -7px rgba(0,0,0,0.1);
                    border-radius: 10px;
                    margin: 20px;
                    p {
                        text-align: center;
                        color: $text;
                        font-size: 20px;
                    }
                    .socials {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 60px;
                            padding: 10px;
                        }
                    }
                }
            }
        }
        .projects {
            width: 90%;
            //height: 100vh;
            padding-top: 50px;
            margin-left: 5%;
            margin-right: 5%;
            h1 {
                background-image: linear-gradient(to bottom right, #A83279, #D38312);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; 
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
                font-size: 45px;
                font-weight: 700;
                padding: 50px;
            }
        }
        .contact {
            width: 100%;
            //height: 100vh;
            //background-image: linear-gradient(to bottom right,  #A83279, #D38312);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .contactDetails {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 30%;
                .contactTitle {
                    h1 {
                    // background-image: linear-gradient(to bottom right, #A83279, #D38312);
                    background-color: $text;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent; 
                        -moz-background-clip: text;
                        -moz-text-fill-color: transparent;
                        font-size: 45px;
                        font-weight: 700;
                        padding: 50px;
                    }
                }
                .contactBox {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 20px;
                    //width: 40%;
                    .contactEmail {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        margin-bottom: 20px;
                        img {
                            width: 35px;
                            padding-right: 40px;
                        }
                        .emailInfo {
                            h2 {
                                margin-top: 0;
                                margin-bottom: 10px;
                            }
                            h4 {
                                margin: 0;
                                color: $text;
                            }
                            a{
                                text-decoration: none;
                                color: inherit;
                            }
                        }
                    }
                    .contactLocation {
                        display: flex;
                        width: 100%;
                        justify-content: flex-start;
                        img {
                            width: 35px;
                            padding-right: 40px;
                        }
                        .locationInfo {
                            h2 {
                                margin-top: 0;
                                margin-bottom: 10px;
                            }
                            h4 {
                                margin: 0;
                                color: $text;
                            }
                            a{
                                text-decoration: none;
                                color: inherit;
                            }
                        }
                    }
                }
            }
            .footer {
                //background-image: linear-gradient(to bottom right, #A83279, #D38312);
                background-color: #ffffff;
                bottom: 0;
                width: 90%;
                //height: 100px;
                margin-bottom: 20px;
                -webkit-box-shadow: 10px -7px 50px -7px rgba(0,0,0,0.1);
                -moz-box-shadow: 10px -7px 50px -7px rgba(0,0,0,0.1);
                box-shadow: 10px -7px 50px -7px rgba(0,0,0,0.1);
                border-radius: 10px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                h1 {
                    color: $text;
                    font-size: 22px;
                }
                .footerBox {
                    img {
                        width: 40px;
                        padding: 15px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1920px){ 
    .container {
        .primary {
            .profile {
                background-position: 270px 250px;
                background-size: 50%;
                img {
                    max-width: 400px;
                }
            }
        }
    }
}

@media (max-width: 1600px){ 
    .container {
        .primary {
            .profile {
                background-position: 270px 250px;
                background-size: 50%;
                img {
                    max-width: 400px;
                }
            }
        }
    }
}

@media (max-width: 1300px){ 
    .container {
        .primary {
            .profile {
                background-position: 270px 250px;
                background-size: 60%;
                img {
                    max-width: 400px;
                }
            }
        }
    }
}
@media (max-width: 1100px){ 
    .container {
        .primary {
            .profile {
                background-position: 250px 250px;
                background-size: 60%;
                .greeting {
                    padding-left: 0;
                }
                img {
                    max-width: 350px;
                }
            }
        }
    }
}

@media (max-width: 426px){
    .container {
        .primary {
            height: auto;
            .profile {
                flex-direction: column-reverse;
                justify-content: flex-start;
                align-items: center;
                background-image: none;
                background-size: 0;
                height: 500px;
                img {
                    max-width: 250px;
                }
                .greeting {
                    margin-top: 0;
                    margin-left: 0;
                    h4 {
                        display: flex;
                        margin: 5px;
                        font-size: 18px;
                        background-image: linear-gradient(to bottom right, #A83279, #D38312);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent; 
                        -moz-background-clip: text;
                        -moz-text-fill-color: transparent;
                        
                    }
                }
            }
        }
        .about {
            h1 {
                padding: 0;
            }
            .aboutText {
                flex-direction: column;
                padding-left: 32px;
                padding-right: 32px;
                .aboutLeft {
                    padding: 10px;
                    margin: 0;
                    p {
                        margin-bottom: 0;
                        margin-top: 35px;
                    }
                    .flags {
                        img {
                            width: 40px;
                        }
                    }
                }
                .aboutRight {
                    padding: 0;
                    .socials {
                        img {
                            width: 40px;
                            margin-bottom: 35px;
                        }
                    }
                }
            }
        }
        .contact {
            flex-direction: column;
            .contactDetails {
                flex-direction: column;
                .contactTitle{
                    h1 {
                        margin-top: 0;
                    }
                }
            }
            .footer {
                flex-direction: column;
                h1 {
                    font-size: 12px;
                    
                }
                .footerBox {
                    img {
                        width: 30px;
                    }
                }
            }
        }
    }
}