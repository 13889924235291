@import 'styles/variables';

// @media (prefers-color-scheme: dark) {
//     .container {
//         .navbar {
//             background: rgba(19, 19, 19, 0.5);
//             border: 1px solid rgba( 19, 19, 19, 0.18 );
//             ul {
//                 li {
//                     color: $textLight;
//                 }
//             }
//         }
//     }
// }
// @media (prefers-color-scheme: light) {
//     .container {
//         .navbar {
//             background: rgba( 255, 255, 255, 0.5 );
//             border: 1px solid rgba( 255, 255, 255, 0.18 );
//             ul {
//                 li {
//                     color: $secondary;
//                 }
//             }
//         }
//     }
// }

.container {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    padding-top: 5px;
    padding-right: 5px;
    .navbar {
        //background: rgba( 255, 255, 255, 0.5 );
        backdrop-filter: blur( 10px );
        -webkit-backdrop-filter: blur( 10px );
        
        //border: 1px solid rgba( 255, 255, 255, 0.18 );
        border-radius: 40px;
        ul {
            display: flex;
            list-style-type: none;
            padding-right: 20px;
            li {
                padding: 20px;
                //color: $secondary;
                font-size: 20px;
                font-weight: 500;
                cursor: pointer;
                a{
                    text-decoration: none;
                    color: inherit;
                }
            }
            li:hover {
                background-image: linear-gradient(to bottom right, #A83279, #D38312);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; 
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
            }
        }
    }
}

@media (max-width: 425px){
    .container {
        justify-content: center;
        padding: 0;
        .navbar {
            border-radius: 0;
            width: 100%;
            padding: 0;
            ul{
                justify-content: space-around;
                padding: 0;
                li{
                    padding: 10px
                }
            }
        }
    }
}
